import type { FC } from 'react'

import { Box } from '@mui/material'

const LoadingScreen: FC = () => (
  <Box
    sx={{
      minHeight: '100%',
    }}
  />
)

export default LoadingScreen
