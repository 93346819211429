import { FC } from 'react'

import { Box } from '@mui/material'

import CardViewLayout from '../components/View/CardViewLayout'

const CardViewPage: FC = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        px: 1,
        bgcolor: '#2e4966',
      }}
    >
      <CardViewLayout />
    </Box>
  )
}

export default CardViewPage
