import { FC } from 'react'

import { ImageList } from '@mui/material'

import xivImages from './../../data/xivSample'
import BaseImageView from './BaseImageView'
const CardViewLayout: FC = () => {
  return (
    <ImageList cols={2}>
      {xivImages.map((image) => (
        <BaseImageView image={image} />
      ))}
    </ImageList>
  )
}

export default CardViewLayout
