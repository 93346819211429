import React, { Suspense, lazy } from 'react'

import type { RouteObject } from 'react-router'

import BaseLayOut from './components/BaseLayout'
import LoadingScreen from './components/LoadingScreen'
import CardViewPage from './pages/CardViewPage'

const Loadable = (Component: React.FC) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )
}

// Login pages
const Login = Loadable(lazy(() => import('./pages/Login')))

// Error pages

const routes: RouteObject[] = [
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: '/',
    element: <BaseLayOut />,
    children: [
      {
        index: true,
        element: <CardViewPage />,
      },
      {
        path: 'ranking',
        element: <div>ranking</div>,
      },
      {
        path: 'post',
        children: [
          {
            index: true,
            element: <div>Post</div>,
          },
          {
            path: ':post_id',
            element: <div>Post detail page</div>,
          },
        ],
      },
      {
        path: 'config',
        element: <div>config</div>,
      },
    ],
  },

  {
    path: '/*',
    element: <div>wild card</div>,
  },
]

export default routes
