import type { FC } from 'react'

import { CssBaseline } from '@mui/material'
import { brown, common } from '@mui/material/colors'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Toaster } from 'react-hot-toast'
import { useRoutes } from 'react-router-dom'

import routes from './routes'

const theme = createTheme({
  palette: {
    mode: 'dark',
    // palette values for dark mode
    primary: brown,
    divider: common.white,
    background: {
      default: '#132F4C',
      paper: '#132F4C',
    },
    text: {
      primary: '#fff',
    },
  },
})

const App: FC = () => {
  const content = useRoutes(routes)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster />
      {content}
    </ThemeProvider>
  )
}

export default App
