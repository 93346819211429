import { FC } from 'react'

import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Outlet } from 'react-router-dom'

import Sidebar from './Sidebar'

const RayoutRoot = styled(Box)({
  width: '100vw',
  height: '100vh',
})

const ContentRoot = styled(Box)({
  display: 'flex',
  paddingLeft: '280px',
  width: '100%',
  height: '100%',
})

const BaseLayOut: FC = () => {
  return (
    <RayoutRoot>
      <Sidebar />
      <ContentRoot>
        <Outlet />
      </ContentRoot>
    </RayoutRoot>
  )
}
export default BaseLayOut
