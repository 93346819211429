import { FC, useRef } from 'react'

import { FavoriteSharp } from '@mui/icons-material'
import { Box, IconButton, ImageListItem, ImageListItemBar } from '@mui/material'
import { useHover } from 'usehooks-ts'

interface BaseImageViewProps {
  image: any
}

const BaseImageView: FC<BaseImageViewProps> = ({ image }) => {
  const hoverRef = useRef(null)
  const isHoverd = useHover(hoverRef)

  return (
    <Box ref={hoverRef}>
      <ImageListItem key={image}>
        <img
          src={image}
          alt={image.index}
          loading="lazy"
          style={{
            borderTopLeftRadius: '11% 17%',
            // borderTopRightRadius: '20px 10px',
            borderBottomRightRadius: '15% 10%',
            // borderBottomLeftRadius: '40px 100px',
            padding: '4px',
          }}
        />
        {isHoverd && (
          <ImageListItemBar
            ref={hoverRef}
            title={'test title'}
            subtitle={'subtitle'}
            actionIcon={
              <IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }}>
                <FavoriteSharp />
              </IconButton>
            }
          />
        )}
      </ImageListItem>
    </Box>
  )
}

export default BaseImageView
