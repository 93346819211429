const image_1 = require('./../assets/sample/xiv/1.png')
const image_10 = require('./../assets/sample/xiv/10.jpg')
const image_11 = require('./../assets/sample/xiv/11.jpg')
const image_12 = require('./../assets/sample/xiv/12.jpg')
const image_13 = require('./../assets/sample/xiv/13.jpg')
const image_14 = require('./../assets/sample/xiv/14.jpg')
const image_15 = require('./../assets/sample/xiv/15.jpg')
const image_16 = require('./../assets/sample/xiv/16.jpg')
const image_2 = require('./../assets/sample/xiv/2.jpg')
const image_3 = require('./../assets/sample/xiv/3.jpg')
const image_4 = require('./../assets/sample/xiv/4.jpg')
const image_5 = require('./../assets/sample/xiv/5.jpg')
const image_6 = require('./../assets/sample/xiv/6.jpg')
const image_7 = require('./../assets/sample/xiv/7.jpg')
const image_8 = require('./../assets/sample/xiv/8.jpg')
const image_9 = require('./../assets/sample/xiv/9.jpg')

const images = [
  image_1,
  image_2,
  image_3,
  image_4,
  image_5,
  image_6,
  image_7,
  image_8,
  image_9,
  image_10,
  image_11,
  image_12,
  image_13,
  image_14,
  image_15,
  image_16,
]
export default images
