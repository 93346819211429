import { FC, Fragment } from 'react'

import { Delete, Person, Settings } from '@mui/icons-material'
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'

const sections = [
  {
    items: [
      {
        title: 'Main',
        path: '/',
        icon: <Delete fontSize={'medium'} />,
      },
      {
        title: 'Ranking',
        path: '/ranking',
        icon: <Person fontSize={'medium'} />,
      },
    ],
  },
  {
    items: [
      {
        title: 'Post',
        path: '/post',
        icon: <Delete fontSize={'medium'} />,
      },
    ],
  },
  {
    items: [
      {
        title: 'Config',
        path: '/config',
        icon: <Settings fontSize={'medium'} />,
      },
    ],
  },
]

const Sidebar: FC = () => {
  const location = useLocation()
  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <List>
        {sections.map((section, index) => {
          return (
            <Fragment key={index}>
              {section.items.map((item) => {
                return (
                  <ListItem key={item.path}>
                    <ListItemButton component={RouterLink} to={item.path} selected={location.pathname === item.path}>
                      <ListItemIcon sx={{ display: 'flex', alignItems: 'center' }}>
                        {item.icon}
                        <ListItemText primary={item.title} sx={{ ml: 1 }} />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                )
              })}
              <Divider />
            </Fragment>
          )
        })}
      </List>
    </Box>
  )

  return (
    <Drawer
      anchor="left"
      PaperProps={{
        sx: {
          width: 280,
        },
      }}
      variant="permanent"
    >
      {content}
    </Drawer>
  )
}

export default Sidebar
